export const CONTACT_US_SUBMISSION = "contact_us_submission";
export const MAINTENANCE_PLUS_SUBMISSION = "maintenance_plus_submission";
export const BOOK_SERVICE_SUBMISSION = "book_service_submission";
export const CAMPAIGN_FORM_SUBMISSION = "campaign-form_submission";

export const trackAnalytics = (
  eventName,
  clickActionText,
  companyName,
  companyAddress
) => {
  window.dataLayer.push({
    event: eventName,
    button_name: clickActionText,
    company_name: companyName,
    company_address: companyAddress,
  });
};

export const dataPush = (eventName, companyName, companyAddress, location) => {
  let data = {};

  const companyDetails = {
    event: eventName,
    company_name: companyName,
    company_address: companyAddress,
    page_url: location,
  };

  const carrerDetails = {
    event: eventName,
    company_name: companyName,
    company_address: companyAddress,
    previous_page_url: location,
  };

  if (eventName === "job_application_click") {
    data = { ...carrerDetails };
  } else {
    data = { ...companyDetails };
  }

  window.dataLayer.push({ ...data });
};
