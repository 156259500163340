import React, { useEffect, useState } from "react";
import styles from "./termsAndConditionGeography.module.css";
import DynamicTrays from "../../Components/DynamicTrays";
import { useSelector, useDispatch } from "react-redux";
import ErrorPage from "../../Components/ErrorPage";
import useMetaDetails from "../../hooks/useMetaDetails";
import { useParams } from "react-router-dom";
import { fetchTermsAndConditionsGeography } from "../../redux-toolkit/slice/termsAndConditionGeography/termsAndConditionGeographySlice";

const TermsAndConditionGeography = () => {

  const { geographyName } = useParams();

  const {
    data = {},
    isError,
    statusCode,
  } = useSelector((state) => state.termsAndConditionsGeography);
  const dispatch = useDispatch();
  const [isPageContainsError, setIsPageContainsError] = useState(false);

  useMetaDetails(data);

  useEffect(() => {
    const payLoad = `?GeographyName=${geographyName}`;
    dispatch(fetchTermsAndConditionsGeography(payLoad));
  }, []);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode]);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div id={styles["termsAndConditionsTitle"]}>
              <h1>{data?.pageHeading}</h1>
            </div>
          </div>
        </div>
      </div>
      {data?.trays?.map((tray, index) => {
        return <DynamicTrays {...tray} key={index} />;
      })}

      {isPageContainsError && <ErrorPage statusCode={statusCode} />}
    </>
  );
};

export default TermsAndConditionGeography;
