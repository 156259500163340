import { ROOT } from "./rootConfig";

export const API_ROOT = process.env.REACT_APP_API_BASE_URL;
export const APIM_KEY = process.env.REACT_APP_OCP_APIM_SUB_KEY;

export const getHeader = () => {
  const requestHeader = {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Cache-Control": "no-cache",
    "Ocp-Apim-Subscription-Key": APIM_KEY,
  };
  return requestHeader;
};

export const constructParameters = (method, body, header) => {
  const params = {
    method,
    headers: getHeader(),
    body: JSON.stringify(body),
  };
  if (header) {
    params.headers = {
      ...params.headers,
      ...header,
    };
  }
  return params;
};

export const responseBody = async (response) => {
  if (response.ok) {
    let json = await response.json();
    return { status: response.status, data: json };
  } else {
    return { status: response.status, data: null };
  }
};

export const handleError = () => Promise.reject();

const requests = {
  get: async (path) => {
    return await fetch(`${API_ROOT}${path}`, { headers: getHeader() })
      .then(responseBody)
      .catch(handleError);
  },
  post: async (path, body) => {
    return await fetch(`${API_ROOT}${path}`, constructParameters("POST", body))
      .then(responseBody)
      .catch(handleError);
  },
};

const NetworkManager = {
  lineOfBusiness: (params) => {
    const path = ROOT.lineOfBusiness + params;
    return requests.get(path);
  },
  lineOfBusinessService: (params) => {
    const path = ROOT.lineOfBusinessService + params;
    return requests.get(path);
  },
  lineOfBusinessServiceDetails: (params) => {
    const path = ROOT.lineOfBusinessServiceDetails + params;
    return requests.get(path);
  },
  lineOfBusinessServiceLocations: (params) => {
    const path = ROOT.lineOfBusinessServiceLocations + params;
    return requests.get(path);
  },
  PrivacyPolicy: (params) => {
    const path = ROOT.PrivacyPolicy + params;
    return requests.get(path);
  },
  TermsAndConditions: () => {
    const path = ROOT.TermsAndConditions;
    return requests.get(path);
  },
  TermsAndConditionsGeography: (params) => {
    const path = ROOT.TermsAndConditionsGeography + params;
    return requests.get(path);
  },
  OffersTermsAndConditions: () => {
    const path = ROOT.OffersTermsAndConditions;
    return requests.get(path);
  },
  franchiseHome: (params) => {
    const path = ROOT.franchiseHome + params;
    return requests.get(path);
  },
  franchiseSEOCity: (params) => {
    const path = ROOT.franchiseSEOCity + params;
    return requests.get(path);
  },
  franchiseOurCompany: (params) => {
    const path = ROOT.franchiseOurCompany + params;
    return requests.get(path);
  },
  franchiseCarrers: (params) => {
    const path = ROOT.franchiseCareers + params;
    return requests.get(path);
  },
  franchiseMaintenance: (params) => {
    const path = ROOT.franchiseMaintenance + params;
    return requests.get(path);
  },
  franchiseCommercial: (params) => {
    const path = ROOT.franchiseCommercial + params;
    return requests.get(path);
  },
  franchiseHeader: (params) => {
    const path = ROOT.franchiseHeader + params;
    return requests.get(path);
  },
  franchiseContact: (params) => {
    const path = ROOT.franchiseContact + params;
    return requests.get(path);
  },
  franchiseBookingService: (params) => {
    const path = ROOT.franchiseBookingService + params;
    return requests.get(path);
  },
  franchiseFAQ: (params) => {
    const path = ROOT.franchiseFAQ + params;
    return requests.get(path);
  },
  franchiseCampaign: (params) => {
    const path = ROOT.franchiseCampaign + params;
    return requests.get(path);
  },
  franchisePromotions: (params) => {
    const path = ROOT.franchisePromotions + params;
    return requests.get(path);
  },
  sitemap: () => {
    const path = ROOT.sitemap;
    return requests.get(path);
  },
  franchiseSendEmail: (body) => {
    const path = ROOT.franchiseSendEmail;
    return requests.post(path, body);
  },
  corporateBlogFilters: () => {
    const path = ROOT.corporateBlogFilters;
    return requests.get(path);
  },
  corporateBlogList: (body) => {
    const path = ROOT.corporateBlogList;
    return requests.post(path, body)
  },
  franchiseBlogs: (params) => {
    const path = ROOT.franchiseBlogs + params;
    return requests.get(path);
  },
  blogPost: (params) => {
    const path = ROOT.blogPost + params;
    return requests.get(path);
  },
  aboutUs: () => {
    const path = ROOT.aboutUs;
    return requests.get(path);
  },
};

export default NetworkManager;
