import React, { useContext } from "react";
import { Markup } from "interweave";

import RdsTabSwitcher from "../../AceHomeServicesRds/src/components/rds-tab-switcher";
import RdsImageCopyTray from "../../AceHomeServicesRds/src/components/rds-image-copy-tray";
import RdsHeroBanner from "../../AceHomeServicesRds/src/components/rds-herobanner";
import RdsBodyCopyTray from "../../AceHomeServicesRds/src/components/rds-body-copy-tray";
import RdsIconTray from "../../AceHomeServicesRds/src/components/rds-icon-tray";
import RdsImageGalleryTray from "../../AceHomeServicesRds/src/components/rds-image-gallery-tray";
import RdsBtn from "../../AceHomeServicesRds/src/components/rds-btn";
import RdsTextLink from "../../AceHomeServicesRds/src/components/rds-textlink";
import RdsMaintenanceContactTray from "../../AceHomeServicesRds/src/components/rds-maintenance-contact-tray";
import RdsFranchiseContactBookingTray from "../../AceHomeServicesRds/src/components/rds-franchise-contact-booking-tray";
import RdsContactForm from "../../AceHomeServicesRds/src/components/rds-contact-form";
import RdsBookServiceForm from "../../AceHomeServicesRds/src/components/rds-book-service-form";
import RdsFormComplete from "../../AceHomeServicesRds/src/components/rds-form-complete";
import RdsAccordion from "../../AceHomeServicesRds/src/components/rds-accordion";
import RdsServicesTray from "../../AceHomeServicesRds/src/components/rds-services-tray";
import RdsServiceAreasTray from "../../AceHomeServicesRds/src/components/rds-service-areas-tray";
import RdsCompanyDetailsTray from "../../AceHomeServicesRds/src/components/rds-company-details-tray";
import RdsBookingJourneyTray from "../../AceHomeServicesRds/src/components/rds-booking-journey-tray";
import RdsCampaignPageBannerForm from "../../AceHomeServicesRds/src/components/rds-campaign-page-banner-form";
import RdsPromotionsTray from "../../AceHomeServicesRds/src/components/rds-promotions-tray";
import RdsFranchiseHomeTileCardTray from "../../AceHomeServicesRds/src/components/rds-franchise-home-tile-card-tray";
import RdsReviewCarousel from "../../AceHomeServicesRds/src/components/rds-review-carousel";
import RdsSeoCityTray from "../../AceHomeServicesRds/src/components/rds-seo-city-tray";
import RdsFeaturedBlogsTray from "../../AceHomeServicesRds/src/components/rds-featured-blogs-tray";
import RdsRecentBlogsTray from "../../AceHomeServicesRds/src/components/rds-recent-blogs-tray";
import RdsPopularBlogsTray from "../../AceHomeServicesRds/src/components/rds-popular-blogs-tray";
import RdsCardTray from "../../AceHomeServicesRds/src/components/rds-card";
import RdsCategoryBlogTray from "../../AceHomeServicesRds/src/components/rds-category-blog-tray";
import RdsBlogPost from "../../AceHomeServicesRds/src/components/rds-blog-post";
import RdsBlogFilter from "../../AceHomeServicesRds/src/components/rds-blog-filter";
import RdsYearList from "../../AceHomeServicesRds/src/components/rds-year-list";
import RDSFilterList from "../../AceHomeServicesRds/src/components/rds-filters-list";
import RdsCorporateBlogsTray from "../../AceHomeServicesRds/src/components/rds-corporate-blogs-tray";
import RdsRecentBlogPostTray from "../../AceHomeServicesRds/src/components/rds-recent-blog-post-tray";
import RdsFranchiseBlogPostTray from "../../AceHomeServicesRds/src/components/rds-franchise-blog-post";
import HeaderContext from "../../context/HeaderCtx";
import RdsOurServicesTray from "../../sections/OurServices";
import { OurServicesData } from "../../constants/services";
import "../../assets/fontawesome/css/all.css";

import { useIsMobile, dynamicTrayButtonAction, getHtmlFromText } from "../../Utilities/Utility";
import "../../assets/fontawesome/css/all.css";

export const trayAvailability = [
  "StickyHeaderTray",
  "HeroBannerTray",
  "ImageCopyTray",
  "BodyCopyTray",
  "IconTray",
  "ImageGallery",
  "MaintenanceContactTray",
  "FranchiseContactAndBookingTray",
  "ContactUs",
  "BookServiceTray",
  "ConfirmationFormTray",
  "AccordionTray",
  "ServicesTray",
  "ServiceAreasTray",
  "CompanyListing",
  "BookingJourneyTray",
  "FranchiseBannerWithFormFill",
  "FranchisePromotionsTray",
  "FranchiseHomeTitleCardTray",
  "ReviewCarousel",
  "SEOCityTray",
  "PromotionsTray",
  "BlogPublishedYearsTray",
  "FilterListTray",
  "CardTray",
  "FeaturedBlogsTray",
  "RecentBlogsTray",
  "PopularBlogsTray",
  "RecentBlogPostsTray",
  "BlogCategoriesTray",
  "BlogPostTray",
  "CorporateBlogsTray",
  "BlogCategoriesFilterTray",
  "RecentBlogsPostTray",
  "FranchiseRecentBlogsTray",
  "FranchiseRecentBlogsTray",
  "OurServicesTray",
  "RelatedBlogPostsTray",
];

export const dynamicTrayList = {
  StickyHeaderTray: RdsTabSwitcher,
  HeroBannerTray: RdsHeroBanner,
  ImageCopyTray: RdsImageCopyTray,
  BodyCopyTray: RdsBodyCopyTray,
  IconTray: RdsIconTray,
  ImageGallery: RdsImageGalleryTray,
  MaintenanceContactTray: RdsMaintenanceContactTray,
  ContactUs: RdsContactForm,
  BookServiceTray: RdsBookServiceForm,
  ConfirmationFormTray: RdsFormComplete,
  AccordionTray: RdsAccordion,
  FranchiseContactAndBookingTray: RdsFranchiseContactBookingTray,
  ServicesTray: RdsServicesTray,
  ServiceAreasTray: RdsServiceAreasTray,
  CompanyListing: RdsCompanyDetailsTray,
  BookingJourneyTray: RdsBookingJourneyTray,
  FranchiseBannerWithFormFill: RdsCampaignPageBannerForm,
  FranchisePromotionsTray: RdsPromotionsTray,
  FranchiseHomeTitleCardTray: RdsFranchiseHomeTileCardTray,
  ReviewCarousel: RdsReviewCarousel,
  SEOCityTray: RdsSeoCityTray,
  CardTray: RdsCardTray,
  BlogCategoriesTray: RdsCategoryBlogTray,
  BlogPostTray: RdsBlogPost,
  BlogCategoriesFilterTray: RdsBlogFilter,
  BlogPublishedYearsTray: RdsYearList,
  FilterListTray: RDSFilterList,
  CorporateBlogsTray: RdsCorporateBlogsTray,
  RecentBlogsPostTray: RdsRecentBlogPostTray,
  PopularBlogsTray: RdsPopularBlogsTray,
  RecentBlogsTray: RdsRecentBlogsTray,
  FeaturedBlogsTray: RdsFeaturedBlogsTray,
  RecentBlogPostsTray: RdsPopularBlogsTray,
  FranchiseRecentBlogsTray: RdsFranchiseBlogPostTray,
  OurServicesTray: RdsOurServicesTray,
  RelatedBlogPostsTray: RdsPopularBlogsTray,
};

export const combineTrayProps = (originalProps, ...otherProps) => {
  let combinedProps;
  //mergin objects data
  const mergeData = (...objects) =>
    objects.reduce((acc, cur) => ({ ...acc, ...cur }));

  if (otherProps) {
    let mergedObj;
    for (let i = 0, len = otherProps?.length; i < len; i++) {
      mergedObj = { ...mergedObj, ...mergeData(otherProps[i]) };
    }
    combinedProps = { ...originalProps, ...mergedObj };
  } else {
    combinedProps = originalProps;
  }

  if (combinedProps?.cta) {
    if (
      combinedProps?.cta.style?.toUpperCase() === "BUTTON" &&
      combinedProps?.cta?.text
    ) {
      combinedProps["renderAction"] = (
        <RdsBtn
          text={combinedProps?.cta?.text}
          onClick={() =>
            dynamicTrayButtonAction(
              combinedProps?.cta?.redirectUrl,
              combinedProps?.cta?.openInNewTab
            )
          }
          buttonClass="rds rds-btn primary"
        />
      );
    }
    if (
      combinedProps?.cta.style?.toUpperCase() === "TEXT" &&
      combinedProps?.cta?.text
    ) {
      combinedProps["renderAction"] = (
        <RdsTextLink
          text={combinedProps?.cta?.text}
          link={combinedProps?.cta?.redirectUrl}
          // target={"_blank"}
          linkClass="rds rds-text-link primary"
        />
      );
    }
  }
  return combinedProps;
};

const DynamicTrays = (props) => {
  const isMobile = { isMobile: useIsMobile() };
  const { franchiseData, isFranchiseRibbonPresent } = useContext(HeaderContext);
  if (
    trayAvailability.includes(props?.type) &&
    props?.type === "AccordionTray"
  ) {
    const AccordionComponent = dynamicTrayList[props?.type];
    return props?.accordionItems?.map((details, index) => {
      return (
        <AccordionComponent title={details?.accordionTitle} id={index}>
          <RdsAccordion.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                fontSize: "16px",
                fontFamily: "roboto",
                fontWeight: 400,
              }}
            >
              <Markup content={details?.accordionContent} />
            </div>
          </RdsAccordion.Body>
        </AccordionComponent>
      );
    });
  } else if (
    trayAvailability.includes(props?.type) &&
    props?.type === "OurServicesTray"
  ) {
    return <RdsOurServicesTray contentData={OurServicesData} />;
  } else if (trayAvailability.includes(props?.type)) {
    const showExternalFranchiseMessage = props?.externalFranchiseMessage;
    const SelectComponent = dynamicTrayList[props?.type];
    return (
      <SelectComponent
        {...combineTrayProps(props, isMobile)}
        franchiseData={franchiseData}
        isLastVisitedFranchiseRibbonActive={isFranchiseRibbonPresent}
        externalFranchiseMessage={showExternalFranchiseMessage}
        getHtmlFromText={getHtmlFromText}
      />
    );
  } else {
    return null;
  }
};

export default DynamicTrays;
