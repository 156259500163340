import DOMPurify from 'dompurify';

export const VIEWPORTWIDTHMD = 768;

export const useIsMobile = () => {
  let isMobile = false

  const handleResize = () => {
    if (window.screen.width < VIEWPORTWIDTHMD) {
      isMobile = true;
    } else {
      isMobile = false;
    }
  };

  window.addEventListener("resize", handleResize);

  if (window.screen.width < VIEWPORTWIDTHMD) {
    isMobile = true;
  }
  return isMobile;
};

export const useIsTab = () => {
  let isTab = false

  const handleResize = () => {
  if(window.screen.width>767&&window.screen.width<992){
    isTab = true
  }
  else{
    isTab=false
  }
}

  window.addEventListener("resize", handleResize);

  if (window.screen.width>767&&window.screen.width<992) {
    isTab = true;
  }
  return isTab;
};


export const scrollToSpecificPath = (pathname) => {
    if (pathname) {
      const name = document.getElementById(pathname);
      window.scroll({
        top: parseInt(name.offsetTop - 150),
        left: 0,
        behavior: "auto",
      });
    }
}

export const scrollToTopSmooth = () => {
  if (window.scrollY > 0) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
}

export const scrollToTop = () => {
    window.scrollTo(0, 0)
}


export const dynamicTrayButtonAction = (redirectUrl, openInNewTab) => {
  if(redirectUrl && openInNewTab){
    window.open(
      redirectUrl,
      '_blank'
    );
  } else {
    window.open(
      window.location.href = redirectUrl
    );
  }
}



// checks if the recent franchise page's path visited matches the last franchise path already stored
// in local storage. if there's no match, only then does the last path get stored in local storage.
// this is so that we don't redundantly set an item into local storage that is already in local storage.
export const storeFranchiseDataToLS = (typeOfData, recentData) => {
  const setData = (type, data) => {
    if (localStorage.getItem(type) !== data) {
      return localStorage.setItem(type, data);
    }
  }
  return setData(typeOfData, recentData)
}

//get data from LocalStorage
export const getFranchiseDataFromLS = (typeOfData) => { 
  const localValue = localStorage.getItem('franchiseSiteMenuData'); 
  const parsedValue = localValue ? JSON.parse(localValue) : null;
  if (parsedValue && ((parsedValue?.headerMenu && parsedValue?.headerMenu.length > 0) || (parsedValue?.franchiseDetails))) {
    if (localStorage.getItem(typeOfData?.toString())) {
        return localStorage.getItem(typeOfData?.toString())
    }
  }
  return null;
}
//formatting phone numbers to US phone number formatting
//Eg: 1111111111 > (111) 111-1111
export const formatPhoneNumber = (phoneNumberString) => {
  var onlyDigits = ("" + phoneNumberString).replace(/\D/g, "");
  var phoneDigit = onlyDigits.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (phoneDigit) {
    return "("+ phoneDigit?.[1] + ") " + phoneDigit?.[2] + "-" + phoneDigit?.[3];
    
  }
  return null;
};

export const setFranchiseLocalStorage = (details) => {
  if(details){
    localStorage.setItem('franchiseSiteMenuData', JSON.stringify(details))
    // Custom event to catch local storage updates within same tab
    window.dispatchEvent(new Event("franchiseLocalStorageUpdate"))
  }
}

export const getFranchiseHeaderDetailLocalStorage = () => {
  const localValue = localStorage.getItem('franchiseSiteMenuData');
  const parsedValue = localValue ? JSON.parse(localValue) : null;
  return parsedValue && ((parsedValue?.franchiseHeader === undefined) && (parsedValue?.franchiseHeader?.franchiseDetails === undefined) ) ? null : parsedValue?.franchiseHeader?.franchiseDetails;
}


//function to determine NoIndexTag addition
export const setNoIndexTag = () => {
  let setTag = false;
  let noIndexPages = ['book-service-confirmation','contact-us-confirmation','maintenance-confirmation'];
  setTag = noIndexPages.some((page) => window.location.pathname.includes(page));
  return setTag;
};

//function to set recent franchise Path
export const setRecentFranchisePath = ()=>{
  if(window.location.pathname.includes('offices')){
    let franchisePathName = window.location.pathname.split('/').slice(0,3).join('/');
    storeFranchiseDataToLS("recentFranchisePath", franchisePathName);
  }
}

function containsHtmlTags(str) {
  const htmlTagRegex = /<\/?[a-z][\s\S]*>/i;
  return htmlTagRegex.test(str);
}

export function getHtmlFromText(displayText) {
  if (containsHtmlTags(displayText)) {
    const sanitizedText = DOMPurify.sanitize(displayText);
    return <span dangerouslySetInnerHTML={{ __html: sanitizedText}}/>
  }
  else {
    return (
      <span>
        {displayText}
      </span>
    )
  }
}
