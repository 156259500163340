import React from "react";
import "../stylesheets/rds-franchise-contact-booking-tray.css";
import "../stylesheets/rds-bootstrap.css";
import { getTrayStyle, getTextStyle, formatPhoneNumber } from "../utility";

class RdsFranchiseContactBookingTray extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerFranchisePhone: null,
      franchiseDetails: {},
    };
  }

  onClickHandler = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  componentDidMount() {
    if (!this.state.headerFranchisePhone) {
      const franchiseDetailsString = localStorage.getItem(
        "franchiseSiteMenuData"
      );
      if (franchiseDetailsString) {
        const siteMenuData = JSON.parse(franchiseDetailsString);
        const franchiseDetails =
          siteMenuData?.franchiseHeader?.franchiseDetails;
        const headerFranchisePhone = franchiseDetails?.headerFranchisePhone;
        if (headerFranchisePhone) {
          this.setState({ headerFranchisePhone });
        }
        if (franchiseDetails) {
          this.setState({ franchiseDetails });
        }
      }
    }
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    const franchiseDetailsString = localStorage.getItem(
      "franchiseSiteMenuData"
    );
    const siteMenuData = JSON.parse(franchiseDetailsString);
    const franchiseDetails =
      siteMenuData?.franchiseHeader?.franchiseDetails;
    const headerFranchisePhone = franchiseDetails?.headerFranchisePhone;

    if (headerFranchisePhone !== prevState.headerFranchisePhone) {
      return { headerFranchisePhone: headerFranchisePhone };
    }
    return null;
  }


  render() {
    const {
      backgroundColor,
      traySectionHeading,
      heading,
      subHeading,
      anchorTagValue,
    } = this.props;

    const trayStyle = getTrayStyle(backgroundColor);
    const textStyle = getTextStyle(backgroundColor);
    const { headerFranchisePhone, franchiseDetails } = this.state;

    const renderPhoneLink = !headerFranchisePhone ? null : (
      <a href={`tel:${headerFranchisePhone}`} className="text-decoration-none" title={`CALL NOW : ${formatPhoneNumber(headerFranchisePhone)}`}>
        <span className="rds rds-btn secondary phone-icon bold">
          {`CALL NOW: ${formatPhoneNumber(headerFranchisePhone)}`}
        </span>
      </a>
    );

    return (
      <>
        <div className="rds-wrapper" style={trayStyle} id={anchorTagValue}>
          <div className="container">
            <div className="row pb-3">
              {traySectionHeading && (
                <h2 className={`rds-section-heading pb-2`} style={textStyle}>
                  {traySectionHeading}
                </h2>
              )}
              {heading && (
                <h3 className={`rds-heading pb-2`} style={textStyle}>
                  {heading}
                </h3>
              )}
              {subHeading && (
                <h4 className={`rds-subHeading`} style={textStyle}>
                  {subHeading}
                </h4>
              )}
            </div>
            <div className="d-flex justify-content-center">{renderPhoneLink}</div>
          </div>
        </div>
      </>
    );
  }
}

export default RdsFranchiseContactBookingTray;
