import React from "react";
import PropTypes from "prop-types";
import "../stylesheets/rds-bootstrap.css";
import "../stylesheets/rds-common.css";
import Modal from 'react-modal';
import styles from "../stylesheets/rds-modal-popup.module.css";
import CloseButton from "./SvgIconComponents/CloseButton/CloseButton";
import Rdsbtn from "./rds-btn";

const RdsModalPopup = ({
  modalOpen = false,
  onClickOk,
  onClickClose,
  modalDetails = {}
}) => {


  return (
    <div>
      <Modal
        isOpen={modalOpen}
        overlayClassName={styles.overlayCSS}
        className={styles.modalStyle}
      >

        <div className={styles.containtStyle}>
          <div className={styles.closeIconStyle} onClick={() => { onClickClose(false) }}><CloseButton /></div>
          <div className={styles.titleText}>
            <div className="rds-font-title-4">{modalDetails?.heading}</div>
            <div className="rds-font-body-1 mt-3">
              {modalDetails?.summary}</div>
          </div>
          <div className={styles.closebtn}>
            <Rdsbtn text={modalDetails?.cta?.text} buttonClass="rds rds-btn primary expanded" onClick={() => { onClickOk(false) }}></Rdsbtn>
          </div>
        </div>

      </Modal>
    </div>
  );
};

RdsModalPopup.propTypes = {
  modalOpen: PropTypes.bool,
  onClickOk: PropTypes.func,
  onClickClose: PropTypes.func,
  modalDetails: PropTypes.shape({
    campaignEnrollConfirmationForm: PropTypes.shape({
      type: PropTypes.string.isRequired,
      displayOrder: PropTypes.number.isRequired,
      heading: PropTypes.string,
      headingAlignment: PropTypes.string,
      summary: PropTypes.string,
      backgroundColor: PropTypes.string,
      cta: PropTypes.shape({
        text: PropTypes.string,
      }),
    }),
  }),
};

export default RdsModalPopup;
