import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMatch } from "react-router-dom";
import { Helmet } from "react-helmet";

import RdsHeaderTray from "../../AceHomeServicesRds/src/components/rds-header-tray";
import { fetchFranchiseHeader } from "../../redux-toolkit/slice/franchiseHeader/franchiseHeaderSlice";
import useFranchiseNameForExternalFranchise from "../../hooks/useFranchiseNameForExternalFranchise";
import useDisplayMsgForExternalFranchise from "../../hooks/useDisplayMsgForExternalFranchise";
import {
  setRecentFranchisePath,
  setFranchiseLocalStorage,
  setNoIndexTag,
} from "../../Utilities/Utility";
import {
  /* common data loading from the header constant file */
  logo,
  rootMenu,
  footerLinks,
  serviceRequestData,
} from "../../constants/header";

const Header = () => {
  const dispatch = useDispatch();

  /* Last visited franchise data initial state */
  const initLVFranchiseState = {
    franchiseName: "",
    franchiseCity: "",
    franchiseStateAbbreviation: "",
    franchisePhoneNumber: "",
    franchiseAddress: "",
  };

  /* franchiseRibbon initial state */
  const initFranchiseRibbon = {
    recentFranchisePath: "",
    isLastVisitedFranchiseRibbonActive: false,
    isSecondaryNavigationActive: false,
    headerMenu: [],
  };

  //Initialize franchiseData state if LS has the data || {}
  const initFranchiseData = () => {
    const savedData = localStorage.getItem("franchiseSiteMenuData");
    return savedData ? JSON.parse(savedData) : {};
  };

  const [franchiseData, setFranchiseData] = useState(initFranchiseData);
  const [lastVisitedFranchiseData, setLastVisitedFranchiseData] =
    useState(initLVFranchiseState);
  const [franchiseRibbonData, setFranchiseRibbonData] =
    useState(initFranchiseRibbon);

  const match = useMatch("/offices/:franchiseName/*");
  const franchiseNameParam = match?.params?.franchiseName ?? "";
  const externalFranchiseNameParam =
    useFranchiseNameForExternalFranchise("officesource");

  const externalFranchiseMessage =
    useDisplayMsgForExternalFranchise("officesource");

  /*
  Accessing the Redux State Slice of Franchise Header 'data',
  Fracnhise Home api statusCode for checking whether FranchiseHome 'data'
  available in Redux State Slice. 
  Based on 'StatusCode', the 'recentFranchisePath', 'franchiseSiteMenuData' updated in the LS,
  */
  const { data } = useSelector((state) => state.franchiseHeader);
  const { statusCode } = useSelector((state) => state.franchiseHome);

  //Dispating the payload to api call
  useEffect(() => {
    let payLoad = "";

    if (franchiseNameParam) {
      payLoad = `?FranchiseName=${franchiseNameParam}`;
    } else if (externalFranchiseNameParam) {
      const externalFranchiseStored = localStorage.getItem(
        "ExtrenalFranchiseStrip"
      );

      if (externalFranchiseStored !== externalFranchiseNameParam) {
        localStorage.setItem(
          "ExtrenalFranchiseStrip",
          externalFranchiseNameParam
        );
        payLoad = `?FranchiseName=${externalFranchiseNameParam}`;
      }
    }

    if (payLoad) {
      dispatch(fetchFranchiseHeader(payLoad));
    }
  }, [franchiseNameParam, externalFranchiseNameParam, dispatch]);

  /**
   *  @function useEffect
   */
  useEffect(() => {
    /* 
    On Redux 'data' change the FranchiseData state updated,
    LocalStorage updated
     */
    if (data) {
      setFranchiseData({ ...data });
      setFranchiseLocalStorage({ ...data });
    }

    /*
    On the FranchiseHome 'statusCode', the recentFranchisePath
    added in LS.
    If anyother statusCode, the key/value deleted from LS.
    */

    if (statusCode === 200) {
      setRecentFranchisePath();
    }
  }, [data, statusCode]);

  useEffect(() => {
    /*
    Based on franchiseData, the lastVisitedFranchise data,
    FranchiseRibbon, SubMenu data ribbon flags, secondaryNv flags updated.
    */
    if (!Object.keys(franchiseData)?.length) {
      setLastVisitedFranchiseData(initLVFranchiseState);
      setFranchiseRibbonData(initFranchiseRibbon);
    } else {
      //If FranchiseData present, updating state with last visited franchise details
      const { franchiseDetails = {}, headerMenu = [] } =
        franchiseData?.franchiseHeader;
      const {
        headerFranchiseName,
        headerFranchiseCity,
        headerFranchiseStateAbbreviation,
        headerFranchisePhone,
        headerFranchiseAddress,
      } = franchiseDetails;

      setLastVisitedFranchiseData({
        franchiseName: headerFranchiseName,
        franchiseCity: headerFranchiseCity,
        franchiseStateAbbreviation: headerFranchiseStateAbbreviation,
        franchisePhoneNumber: headerFranchisePhone,
        franchiseAddress: headerFranchiseAddress,
      });

      const recentFranchisePath = localStorage
        .getItem("recentFranchisePath")
        ?.toString();
      const isSecondaryNavigationActive = headerMenu?.length > 0 ? true : false;
      setFranchiseRibbonData({
        recentFranchisePath,
        isLastVisitedFranchiseRibbonActive: true,
        isSecondaryNavigationActive,
        headerMenu: isSecondaryNavigationActive ? headerMenu : [],
      });
    }
  }, [franchiseData]);

  /**
   * constant headerProps
   * Contains all the variables and objects needed to display the header
   */
  const headerProps = {
    ...{ logo },
    ...{ rootMenu },
    ...{ footerLinks },
    ...{ serviceRequestData },
    ...{ lastVisitedFranchiseData },
    ...{ ...franchiseRibbonData },
  };

  const isNoIndexTag = setNoIndexTag();

  return (
    <>
      <Helmet>
        {isNoIndexTag && <meta name="robots" content="noindex" />}
      </Helmet>
      <RdsHeaderTray
        {...headerProps}
        franchiseId={externalFranchiseMessage?.id}
      />
    </>
  );
};

export default Header;
