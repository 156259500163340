import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "../stylesheets/rds-campaign-page-banner-form.module.css";
import "../stylesheets/rds-bootstrap.css";
import RdsInputText from "./rds-inputtext";
import RdsSelectInput from "./rds-select-dropdown";
import Rdsbtn from "./rds-btn";
import { formatPhoneNumber } from "../utility";
import { formInputValidations, getDateInputISO } from "../utility";
import "../stylesheets/rds-common.css";

const RdsCampaignPageBannerForm = ({
  imageUrl,
  logoImage,
  logoPosition,
  backgroundColor,
  heading,
  formFields,
  onFormSubmission,
  cta,
  formSubmissionError,
}) => {
  const initializeFields = () => {
    const stateObj = {};
    formFields?.forEach((input) => {
      stateObj[input.name] = "";
    });
    return stateObj; 
  };

  // Lazy load initial state
  const [inputs, setInputs] = useState(initializeFields);
  const [fixInputs, setFixInputs] = useState(false);

  const handleInputChange = (e) => {
    const input = e.target;
    setInputs({
      ...inputs,
      [input.name]: input.value,
    });
  };

  const handleSubmitForm = () => {
    // Ensures nothing is focused after submit click
    document.activeElement.blur();
    let errorCount = 0;

    for (const name of Object.keys(inputs)) {
      const input = document.getElementById(name);
      if (input) {
        input.focus();
        input.blur();
        if (input.closest(".rds-input-text")?.classList?.contains("error")) {
          // If there is any error displaying, increment errorCount
          errorCount++;
        }
      }
    }

    if (onFormSubmission && errorCount === 0) {
      const userData = {
        firstName: inputs.firstName || "",
        lastName: inputs.lastName || "",
        emailAddress: inputs.email || "",
        phoneNumber: formatPhoneNumber(inputs.phoneNumber) || "",
        zipCode: inputs.zipCode || "",
        serviceType: inputs.serviceType || "",
        jobType: inputs.jobType || "",
        scheduledDate:
          new Date(inputs.scheduledDate).toLocaleDateString("en-US") || "",
      };

      const btn = document.getElementById("rds-btn-campaign-form");
      if (btn) {
        //be sure button exists before handling it
        btn.innerHTML = "<span>PLEASE WAIT...</span>";
        btn.disabled = true;
      }

      setFixInputs(false);
      onFormSubmission(userData, setInputs, initializeFields, setDropDownInitialValues);
    } else {
      setFixInputs(true);
    }
  };

  // Initially set dropdowns since igx is not currently passing an "empty" value
  // (always has a value, but need to set state)
  const setDropDownInitialValues = () => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      serviceType: document.getElementById("serviceType")?.value || "",
      jobType: document.getElementById("jobType")?.value || "",
    }));
  }

  useEffect(() => {
    setDropDownInitialValues();
  }, []);

  const getDateFormatLabel = (labelText) =>
    labelText === "When do you want to schedule your service?*";
  const getDropdownValue = (fieldData) => {
    let dropDownValues = [];
    const { name } = fieldData;
    if (name === "serviceType") {
      dropDownValues = [...fieldData?.serviceTypes];
    } else if (name === "jobType") {
      if (!inputs?.length) {
        dropDownValues = [...fieldData?.jobTypes?.Heating];
      }
      if (inputs?.serviceType?.length) {
        dropDownValues = [...fieldData?.jobTypes?.[inputs?.serviceType]];
      }
    }
    return dropDownValues;
  };

  const getInputField = (fieldDetails) => {
    const isDateFormatLabelActive = getDateFormatLabel(fieldDetails?.name);
    return (
      <div
        className={`${fieldDetails?.name == "email" ||
            fieldDetails?.name == "phoneNumber" ||
            fieldDetails?.name == "zipCode"
            ? "col-md-4"
            : "col-md-6"
          } col-xs-12`}
      >
        <RdsInputText
          type={
            fieldDetails?.controlType?.toLowerCase() === "phone"
              ? "tel"
              : fieldDetails?.controlType?.toLowerCase()
          }
          containerClass="rds rds-input-text"
          id={fieldDetails?.name}
          text={fieldDetails?.name}
          value={inputs?.[fieldDetails?.name]}
          dateFormatLabel={isDateFormatLabelActive}
          name={fieldDetails?.name}
          required={fieldDetails?.isRequired}
          placeholder={fieldDetails?.label}
          errorHelperText={"Please enter in a valid " + fieldDetails?.label}
          onChange={handleInputChange}
        />
      </div>
    );
  };

  const getDropDownField = (fieldDetails) => {
    const dropDownValues = getDropdownValue(fieldDetails);
    const currentValueMap = {
      service: inputs?.service,
      jobType: inputs?.jobType,
    };
    return (
      <div key={fieldDetails?.name}>
        <RdsSelectInput
          backgroundColor={backgroundColor}
          type={fieldDetails?.controlType?.toLowerCase()}
          options={dropDownValues}
          value={currentValueMap[fieldDetails?.name]}
          containerClass="rds rds-input-text"
          id={fieldDetails?.name}
          text={fieldDetails?.name}
          name={fieldDetails?.name}
          required={fieldDetails?.isRequired}
          errorHelperText={"Please enter in a valid " + fieldDetails?.label}
          maxLength={fieldDetails?.MaxLength}
          defaultValue={dropDownValues && dropDownValues[0]}
          onChange={handleInputChange}
          placeholder={fieldDetails?.label}
        />
      </div>
    );
  };

  const getDateField = (fieldDetails) => (
    <div key={fieldDetails?.name}>
      <RdsInputText
        backgroundColor={backgroundColor}
        type={fieldDetails?.controlType?.toLowerCase()}
        containerClass="rds rds-input-text"
        id={fieldDetails?.name}
        text={fieldDetails?.name}
        value={inputs?.[fieldDetails?.name]}
        name={fieldDetails?.name}
        required={fieldDetails?.isRequired}
        errorHelperText={"Please enter in a valid " + fieldDetails?.label}
        onChange={handleInputChange}
        onKeyDown={(e) => e.preventDefault()}
        min={getDateInputISO(formInputValidations.scheduledDate.min)}
        max={getDateInputISO(formInputValidations.scheduledDate.max)}
      />
    </div>
  );

  const getFormFields = (formFieldData) => {
    const { controlType } = formFieldData;

    if (controlType?.toUpperCase() === "DATE") {
      return getDateField(formFieldData);
    } else if (controlType?.toUpperCase() === "DROPDOWNLIST") {
      return getDropDownField(formFieldData);
    }
    return getInputField(formFieldData);
  };
  return (
    <div
      className={`container-fluid ${styles.campaignBanner}`}
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <div className="container h-100">
        <div
          className={`row ${logoPosition === "Right" && "flex-row-reverse"
            } align-items-center`}
        >
          <div className="d-none d-sm-block col-md-4 justify-content-center align-items-center">
            <img
              src={logoImage}
              alt="AceLogo"
              style={{ width: "100%", maxWidth: "437px" }}
            />
          </div>
          <div className={`${styles.formBg} col-md-8 py-3 px-xs-5 px-4`}>
            {heading && (
              <p className="rds-font-title-4 text-white text-center">
                {heading}
              </p>
            )}
            {fixInputs && (
              <div
                className="alert alert-warning rds-font-body-2 d-flex align-items-center mt-3"
                role="alert"
              >
                <i
                  className="fa fa-exclamation-triangle me-2"
                  aria-hidden="true"
                ></i>
                <div>Please complete all required fields.</div>
              </div>
            )}
            {formSubmissionError && (
              <div
                className="alert alert-danger rds-font-body-2 d-flex align-items-center mt-3"
                role="alert"
              >
                <i class="fa fa-exclamation-circle me-2" aria-hidden="true"></i>
                <div>Submission failed. Please reload the page and try to submit the form again.</div>
              </div>
            )}
            <form className="d-flex flex-column gap-3">
              {/* First Row: First Name and Last Name */}
              <div className="row gap-sm-0 gap-3">
                {formFields
                  ?.filter(
                    (fieldDetails) =>
                      fieldDetails?.name === "firstName" ||
                      fieldDetails?.name === "lastName"
                  )
                  .map((fieldDetails) => getFormFields(fieldDetails))}
              </div>
              {/* Second Row: Email, Phone Number, and Zip Code */}
              <div className="row gap-sm-0 gap-3">
                {formFields
                  ?.filter(
                    (fieldDetails) =>
                      fieldDetails?.name === "email" ||
                      fieldDetails?.name === "phoneNumber" ||
                      fieldDetails?.name === "zipCode"
                  )
                  .map((fieldDetails) => getFormFields(fieldDetails))}
              </div>
              {/* Third Row: Date Select Input */}
              <div className="row">
                {formFields
                  ?.filter(
                    (fieldDetails) => fieldDetails?.name === "scheduledDate"
                  )
                  .map((fieldDetails) => (
                    <div className="col-md-12 col-xs-12">
                      <div className="rds-font-body-2 text-white">
                        {fieldDetails?.label}
                      </div>
                      {getFormFields(fieldDetails)}
                    </div>
                  ))}
              </div>
              {/* Fourth Row: Service and Job Type Select Inputs */}
              <div className="row">
                {formFields
                  ?.filter(
                    (fieldDetails) =>
                      fieldDetails?.name === "serviceType" ||
                      fieldDetails?.name === "jobType"
                  )
                  .map((fieldDetails) => {
                    return (
                      <div className="col-md-6 col-xs-12">
                        <div
                          className="rds-font-body-2 text-white pb-2"
                          style={{
                            visibility:
                              fieldDetails?.name === "jobType" && "hidden",
                          }}
                        >
                          {formFields
                            ?.filter(
                              (fieldDetails) =>
                                fieldDetails?.name ===
                                "whatkindofservicedoyouneed"
                            )
                            ?.map((fieldDetails) => fieldDetails?.label)}
                        </div>
                        {(fieldDetails?.name === "serviceType" ||
                          fieldDetails?.name === "jobType") && (
                            <div className="row">
                              {getFormFields(fieldDetails)}
                              {fieldDetails?.helperText && (
                                <div className="rds-font-caption text-white">
                                  {fieldDetails?.helperText}
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    );
                  })}
              </div>
              {/* Submit Button */}
              <div className="row">
                <div className="col-md-12">
                  <Rdsbtn
                    id="rds-btn-campaign-form"
                    text={cta?.text}
                    buttonClass="rds rds-btn primary w-100"
                    disabled={formSubmissionError}
                    onClick={handleSubmitForm}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

RdsCampaignPageBannerForm.propTypes = {
  heading: PropTypes.string,
  backgroundColor: PropTypes.string,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isRequired: PropTypes.bool,
      controlType: PropTypes.string.isRequired,
    })
  ).isRequired,
  onFormSubmission: PropTypes.func,
  cta: PropTypes.shape({
    style: PropTypes.string,
    text: PropTypes.string.isRequired,
    redirectUrl: PropTypes.string,
    openInNewTab: PropTypes.bool,
  }),
  formSubmissionError: PropTypes.bool,
};

export default RdsCampaignPageBannerForm;
