import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchFranchiseCampaign } from "../../redux-toolkit/slice/franchiseCampaign/franchiseCampaignSlice";
import DynamicTrays from "../../Components/DynamicTrays";
import ErrorPage from "../../Components/ErrorPage";
import useMetaDetails from "../../hooks/useMetaDetails";
import { dataPush, CAMPAIGN_FORM_SUBMISSION } from "../../Utilities/GAnalytics";
import NetworkManager from "../../redux-toolkit/networkManager/networkConfig";
import { getFranchiseHeaderDetailLocalStorage } from "../../Utilities/Utility";
import RdsModalPopup from "../../AceHomeServicesRds/src/components/rds-modal-popup";

const FranchiseCampaign = () => {
  const { franchiseName, campaignName } = useParams();
  const campaignURL = window.location.href;
  const [formSuccess, setFormSuccess] = useState(false);
  const { data, isError, statusCode } = useSelector(
    (state) => state.franchiseCampaign
  );
  const dispatch = useDispatch();
  const [isPageContainsError, setIsPageContainsError] = useState(false);
  const [formSubmissionError, setFormSubmissionError] = useState(false);

  useMetaDetails(data);

  useEffect(() => {
    const payLoad = `?FranchiseName=${franchiseName}&CampaignName=${campaignName}`;
    dispatch(fetchFranchiseCampaign(payLoad));
  }, [campaignName, franchiseName]);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode]);

  const handleSubmit = (userData, setInputs, initializeFields, setDropDownInitialValues) => {
    const franchiseHeaderDetailLocalStorage = getFranchiseHeaderDetailLocalStorage();
    const franchiseData = {
      name:
        franchiseHeaderDetailLocalStorage?.headerFranchiseName || "",
      phoneNumber:
        franchiseHeaderDetailLocalStorage?.headerFranchisePhone || "",
      emailAddress:
        franchiseHeaderDetailLocalStorage?.headerFranchiseEmail || "",
      url: campaignURL
    };

    const formData = {
      formName: "CAMPAIGN_CONFIRMATION",
      userData,
      franchiseData,
    };

    NetworkManager.franchiseSendEmail(formData).then((response) => {
      if (response.status === 200) {
        dataPush(
          CAMPAIGN_FORM_SUBMISSION,
          franchiseHeaderDetailLocalStorage?.headerFranchiseName,
          franchiseHeaderDetailLocalStorage?.headerFranchiseAddress,
          window.location.href
        );
        setFormSuccess(true);
        setInputs(initializeFields);
        setDropDownInitialValues();
      } else {
        setFormSubmissionError(true);
        setFormSuccess(false)
      }
    });
  };

  const onClickClose = () => {
    setFormSuccess(false);
    const btn = document.getElementById("rds-btn-campaign-form");
    /**
     * If Button Present it will enable the button
     */
    if (btn) {
      data?.trays?.filter((tray) => tray?.type === "FranchiseBannerWithFormFill")?.map((tray) => (btn.innerHTML = `<span>${tray?.cta?.text}</span>`));
      btn.disabled = false;
    }
  };

  return (
    <>
      {data?.trays?.map((tray, index) => {
        return <DynamicTrays {...tray} onFormSubmission={handleSubmit}
          formSubmissionError={formSubmissionError} key={index} />;
      })}

      {formSuccess &&
        <RdsModalPopup
          modalOpen={formSuccess}
          onClickOk={onClickClose}
          onClickClose={onClickClose}
          modalDetails={data?.campaignEnrollConfirmationForm}
        />
      }
      {isPageContainsError && <ErrorPage statusCode={statusCode} />}
    </>
  );
};

export default FranchiseCampaign;
