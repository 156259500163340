import React from "react";
import "../stylesheets/rds-input-text.css";
import "../stylesheets/rds-common.css"

class RdsSelectInput extends React.Component {
  componentDidMount() {
    if (this.props.autoFocus && this.props.forwardedRef) {
      this.props.forwardedRef.current.focus();
      this.props.forwardedRef.current.focus();
      this.props.forwardedRef.current.autofocus = true;
    }

    let isIOS = false;

    if (
      /CriOS/i.test(navigator.userAgent) &&
      /iphone|ipod|ipad/i.test(navigator.userAgent)
    ) {
      isIOS = true;
    }

    var me = this;

    //Only for Chrome IOS.
    if (
      isIOS &&
      this.props.forwardedRef &&
      this.props.forwardedRef.current &&
      this.props.forwardedRef.current.addEventListener
    ) {
      let transitionEventHandler = (e) => {
        setTimeout(() => {
          var input = document.getElementById(me.props.id);
          let target = {
            name: me.props.name,
            value: input?.value || "",
            inputElFromAutoFill: input,
          };
          let event = {
            target: target,
            currentTarget: target,
          };
          if (me.props.onChange) {
            me.props.onChange(event);
          } else if (me.props.onKeyUp) {
            me.props.onKeyUp(event);
          }
        }, 400);
      };
      this.props.forwardedRef.current.addEventListener("transitionend", (e) => {
        var input = document.getElementById(me.props.id);
        if (input != document.activeElement || input?.value)
          transitionEventHandler(e);
      });
    }

    setTimeout(() => {
      var input = document?.getElementById(me?.props?.id);
      if (input?.matches(":-webkit-autofill")) {
        document
          .getElementById(me.props.id + "-label-wrap")
          ?.classList?.add("input-autofilled");
      } else if (input?.matches(":-webkit-autofill")) {
        document
          .getElementById(me.props.id + "-label-wrap")
          ?.classList?.add("input-autofilled");
      }
    }, 500);
  }

  clearInput(e) {
    var input = document.getElementById(this.props.id);
    input.value = "";
    input.focus();
    if (this.props.onClear) {
      e.target = input;
      this.props.onClear(e);
    }
    this.displayClear(e);
  }

  onBlur(e) {
    this.validateInput(e);
    if (this.props.onBlur) this.props.onBlur(e);
  }

  handleKeyUp(e) {
    this.displayClear(e);
  }

  handleFocus(e) {
    this.displayClear(e);
  }

  displayClear(e) {
    var clearBtn = document.getElementById(this.props.id + "-clear-btn");
    if (e.currentTarget.value.length === 0) clearBtn?.classList?.add("hide");
    else if (e.currentTarget.value.length > 0)
      clearBtn?.classList?.remove("hide");
  }

  validateInput(e) {
    var elm = e.currentTarget;
    var value = elm.value;
    var validationText = document.getElementById(
      this.props.id + "-validation-text"
    );
    if (this.props.required) {
      if (!value) {
        validationText.innerHTML = this.props.errorHelperText;
        validationText?.classList?.add("error-text");
        elm.parentElement?.classList?.add("error");
      } else {
        validationText.innerHTML = "";
        validationText?.classList?.remove("error-text");
        elm.parentElement?.classList?.remove("error");
      }
    }
  }

  render() {
    let { onBlur, onChange, value, ...props } = this.props;

    return (
      <div
        key={this.props.key}
        className={
          this.props.containerClass +
          (this.props.required && this.props.errorText ? " error" : "")
        }
      >
        <div>
          <select
            className={
              this.props.className + (this.props.mask ? " gbmask" : "")
            }
            name={this.props.name}
            aria-label={this.props.ariaLabel}
            id={this.props.id}
            key={this.props.defaultValue}
            text={this.props.text}
            placeholder={this.props.text}
            disabled={this.props.disabled}
            onBlur={(e) => this.onBlur(e)}
            onKeyUp={(e) => this.handleKeyUp(e)}
            onKeyPress={this.props.onKeyPress}
            onKeyDown={this.props.onKeyDown}
            maxLength={this.props.maxLength}
            pattern={this.props.pattern}
            inputMode={this.props.inputMode}
            required={this.props.required}
            value={this.props.value}
            onChange={this.props.onChange}
            onInput={this.props.onInput}
            onFocus={(e) => this.handleFocus(e)}
            autoComplete={this.props.autoComplete}
            ref={this.props.forwardedRef}
            defaultValue={this.props.defaultValue}
            {...this.props.dataAttributes}
            style={{ width: "100%", padding: "0.5rem", alignItems: "center" }}
          >
            {this.props.options &&
              this.props.options?.map((item, index) => {
                return <option value={`${item}`}>{item}</option>;
              })}
          </select>
          <div className="label-wrap" id={this.props.id + "-label-wrap"}>
            <label
              htmlFor={this.props.id}
              data-content={this.props.placeholder}
              tabIndex="-1"
            >
              <span className="hidden-label">{this.props.placeholder}</span>
            </label>
          </div>
          {this.props.required && (
            <div
              ref={this.props.validationRef}
              id={this.props.id + "-validation-text"}
              className="rds-input-validation-text error-text rds-font-body-2"
              {...this.props.dataValidationAttributes}
            >
              {this.props.errorText}
            </div>
          )}
          <div
            ref={this.props.helperRef}
            id={
              this.props.id +
              `${
                this.props.backgroundColor === "Black"
                  ? "-campaign-helper-text"
                  : "-helper-text"
              }`
            }
            className={`${
              this.props.backgroundColor === "Black"
                ? "rds-input-campaign-helper-text"
                : "rds-input-helper-text"
            }`}
          >
            {this.props.helperText}
          </div>
        </div>
      </div>
    );
  }
}

export default RdsSelectInput;
