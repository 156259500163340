import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import AboutUs from "./pages/aboutUs";
import HomePage from "./pages/homePage";
import LineOfBusiness from "./pages/lineOfBusiness";
import LineOfBusinessServiceLocations from "./pages/lineOfBusinessServiceLocations";
import LineOfBusinessService from "./pages/lineOfBusinessService";
import LineOfBusinessServiceDetails from "./pages/lineOfBusinessServiceDetails";
import FranchiseCommercial from "./pages/franchiseCommercial";
import FranchisePromotions from "./pages/franchisePromotions";
import FranchiseFAQs from "./pages/franchiseFAQs";
import FranchiseCareers from "./pages/franchiseCareers";
import FranchiseCampaign from "./pages/franchiseCampaign";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import FormSubmissionSuccessPage from "./pages/formSubmissionSuccess";
import Sitemap from "./pages/sitemap";
import TargetPageWithGTM from "./Utilities/TargetPageWithGTM";
import OffersTermsAndConditions from "./pages/OffersTermsAndConditions";
import Blogs from "./pages/blogs";
import FranchiseBlogs from "./pages/franchiseBlogs";
import BlogPost from "./pages/blogPost";
import TermsAndConditionGeography from "./pages/termsAndConditionGeography";

const loading = (
  <div style={{ "z-index": 999 }}>
    <p>loading...</p>
    <p>Please wait</p>
  </div>
);

const RootRouter = () => {
  return (
    <div className="min-height-65">
      <Suspense fallback={loading}>
        <Routes>
          {/* Define static and defined routes here */}
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/faq" element={<FranchiseFAQs />} />
          <Route exact path="/about-us" element={<AboutUs />}></Route>
          <Route exact path="/blogs" element={<Blogs />}></Route>

          {/* Define dynamic routes here */}
          <Route exact path="/:lobType" element={<LineOfBusiness />} />
          <Route
            exact
            path="/:lobType/service-locations"
            element={<LineOfBusinessServiceLocations />}
          />
          <Route
            exact
            path="/:lobType/services/:serviceType"
            element={<LineOfBusinessService />}
          />
          <Route
            exact
            path="/:lobType/service-details/:serviceDetailsType"
            element={<LineOfBusinessServiceDetails />}
          />
          <Route
            exact
            path="/offices/:franchiseName"
            element={<TargetPageWithGTM name="FranchiseHome" />}
          />
          <Route
            exact
            path="/offices/:franchiseName/commercial-heating-and-cooling"
            element={<FranchiseCommercial />}
          />
          <Route
            exact
            path="/offices/:franchiseName/maintenance"
            element={<TargetPageWithGTM name="FranchiseMaintenance" />}
          />
          <Route
            exact
            path="/offices/:franchiseName/maintenance-confirmation"
            element={<FormSubmissionSuccessPage />}
          />
          <Route
            exact
            path="/offices/:franchiseName/our-company"
            element={<TargetPageWithGTM name="FranchiseOurCompany" />}
          />
          <Route
            exact
            path="/offices/:franchiseName/promotions"
            element={<FranchisePromotions />}
          />
          <Route
            exact
            path="/offices/:franchiseName/contact-us"
            element={<TargetPageWithGTM name="FranchiseContactUs" />}
          />
          <Route
            exact
            path="/offices/:franchiseName/contact-us-confirmation"
            element={<FormSubmissionSuccessPage />}
          />
          <Route
            exact
            path="/offices/:franchiseName/book-service"
            element={<TargetPageWithGTM name="FranchiseBookingService" />}
          />
          <Route
            exact
            path="/offices/:franchiseName/book-service-confirmation"
            element={<FormSubmissionSuccessPage />}
          />
          <Route
            exact
            path="/offices/:franchiseName/careers"
            element={<FranchiseCareers />}
          />
          <Route
            exact
            path="/offices/:franchiseName/blogs"
            element={<FranchiseBlogs />}
          />
          <Route
            exact
            path="/blogs/:blogPostName"
            element={<BlogPost />}
          />
          <Route
            exact
            path="/offices/:franchiseName/city/:cityName"
            element={<TargetPageWithGTM name="FranchiseSEOCity" />}
          />
          <Route
            exact
            path="/offices/:franchiseName/campaign/:campaignName"
            element={<FranchiseCampaign />}
          />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            exact
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route
            exact
            path="/terms-and-conditions/:geographyName"
            element={<TermsAndConditionGeography />}
          />
          <Route
            exact
            path="/offers-terms-and-conditions"
            element={<OffersTermsAndConditions />}
          />
          <Route exact path="/sitemap" element={<Sitemap />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default RootRouter;
