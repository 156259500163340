import React, { useState } from "react";
import PropTypes from "prop-types";
import "../stylesheets/rds-contact-form.css";
import "../stylesheets/rds-bootstrap.css";
import RdsInputText from "./rds-inputtext";
import Rdsbtn from "./rds-btn";
import { TEXTAREA } from "../constant";
import { Markup } from "interweave";
import "../stylesheets/rds-common.css"

const RdsContactForm = ({
  heading,
  subheading,
  formFields,
  onFormSubmission,
  cta,
  formSubmissionError,
  termsText,
}) => {
  const initializeFields = () => {
    const stateObj = {};
    formFields?.map((input) => {
      stateObj[input.name] = "";
    });
    return stateObj;
  };

  // Lazy load initial state
  const [inputs, setInputs] = useState(() => initializeFields());
  const [fixInputs, setFixInputs] = useState(false);

  const handleInputChange = (e) => {
    const input = e.target;
    setInputs({
      ...inputs,
      [input.name]: input.value,
    });
  };

  const handleSubmitForm = () => {
    // Ensures nothing is focused after submit click
    document.activeElement.blur();

    let errorCount = 0;

    for (const name of Object.keys(inputs)) {
      const input = document.getElementById(name);
      // A way to onBlur each input, triggering existing validation on each input
      input.focus();
      input.blur();
      // If there is any error displaying, increment errorCount
      if (input.closest(".rds-input-text")?.classList?.contains("error")) {
        errorCount++;
      }
    }

    if (onFormSubmission && errorCount === 0) {
      const userData = {
        firstName: inputs.firstName || "",
        lastName: inputs.lastName || "",
        emailAddress: inputs.emailAddress || "",
        phoneNumber: inputs.phoneNumber || "",
        comments: inputs.leaveUsAComment || "",
      };

      setFixInputs(false);
      onFormSubmission(userData);

      const btn = document.getElementById("rds-btn-contact-us");
      btn.innerHTML = "<span>PLEASE WAIT...</span>";
      btn.disabled = true;
    } else {
      setFixInputs(true);
    }
  };

  return (
    <>
      <div className="rds-contact-form">
        {heading && <h1 className={`rds-heading`}>{heading}</h1>}
        {subheading && (
          <p className={`rds-contact-form-subHeading mt-4`}>{subheading}</p>
        )}
        <div className="row">
          {fixInputs && (
            <div
              className="alert alert-warning d-flex align-items-center mt-3"
              role="alert"
            >
              <i
                className="fa fa-exclamation-triangle me-2"
                aria-hidden="true"
              ></i>
              <div>Please complete all required fields.</div>
            </div>
          )}
          {formFields?.map((fieldDetails) => {
            return fieldDetails?.controlType?.toUpperCase() === TEXTAREA ? (
              <div className="col-md-12 col-xs-12 mt-3 mb-2">
                <RdsInputText
                  key={fieldDetails?.name}
                  type="textarea"
                  placeholder={fieldDetails?.label}
                  rows={5}
                  width={"100%"}
                  containerClass="rds rds-input-text"
                  id={fieldDetails?.name}
                  text={fieldDetails?.name}
                  name={fieldDetails?.name}
                  onChange={handleInputChange}
                />
              </div>
            ) : (
              <div className="col-md-6 col-xs-12 mt-3 mb-2">
                <RdsInputText
                  key={fieldDetails?.name}
                  type={
                    fieldDetails?.controlType?.toLowerCase() === "textbox"
                      ? "text"
                      : fieldDetails?.controlType?.toLowerCase() === "phone"
                      ? "tel"
                      : fieldDetails?.controlType?.toLowerCase()
                  }
                  containerClass="rds rds-input-text"
                  id={fieldDetails?.name}
                  text={fieldDetails?.name}
                  placeholder={fieldDetails?.label}
                  name={fieldDetails?.name}
                  required={fieldDetails?.isRequired}
                  errorHelperText={
                    "Please enter in a valid " + fieldDetails?.label
                  }
                  maxLength={fieldDetails?.MaxLength}
                  onChange={handleInputChange}
                />
              </div>
            );
          })}
          {formSubmissionError && (
            <div
              className="alert alert-danger d-flex align-items-center mt-3"
              role="alert"
            >
              <i
                className="fa fa-exclamation-circle me-2"
                aria-hidden="true"
              ></i>
              <div>
                Submission failed. Please reload the page and try to submit the
                form again.
              </div>
            </div>
          )}
          <div className="rds-contact-form-button mt-2">
            <Rdsbtn
              id="rds-btn-contact-us"
              text={cta?.text}
              buttonClass="rds rds-btn primary"
              disabled={formSubmissionError}
              onClick={handleSubmitForm}
            />
          </div>
        </div>
        {termsText && (
          <p className={`rds-font-caption mt-4 pt-3`}><Markup content={termsText} /></p>
        )}
      </div>
    </>
  );
};

RdsContactForm.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  termsText: PropTypes.string,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isRequired: PropTypes.bool,
      controlType: PropTypes.string.isRequired,
    })
  ).isRequired,
  onFormSubmission: PropTypes.func,
  cta: PropTypes.shape({
    style: PropTypes.string,
    text: PropTypes.string.isRequired,
    redirectUrl: PropTypes.string,
    openInNewTab: PropTypes.bool,
  }),
  formSubmissionError: PropTypes.bool,
};

export default RdsContactForm;
