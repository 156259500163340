import React from "react";
import "../stylesheets/rds-maintenance-contact-tray.css";
import "../stylesheets/rds-bootstrap.css";
import Rdsbtn from './rds-btn';
import { formatPhoneNumber, getTrayStyle } from '../utility';
import RdsContactForm from './rds-contact-form';

class RdsMaintenanceContactTray extends React.Component {

  onClickHandler = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    const {
      headingToLeft,
      subheadingToLeft,
      maintenanceEnrollForm,
      onFormSubmission,
      formSubmissionError,
      anchorTagValue,
      franchiseData
    } = this.props;

    const trayStyle = getTrayStyle('#333');
    const franchiseDetails = franchiseData?.franchiseDetails;

    return (
      <>
        <div className="rds-wrapper" style={trayStyle} id={anchorTagValue}>
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 col-xs-12 d-flex align-items-center pt-4 pb-4">
                <div>
                  {headingToLeft && (
                    <h2
                      className={`rds-maintenance-contact-tray-heading`}
                    >
                      {headingToLeft}
                    </h2>
                  )}
                  {subheadingToLeft && (
                    <h3
                      className={`rds-maintenance-contact-tray-subHeading`}
                    >
                      {subheadingToLeft}
                    </h3>
                  )}
                  <div className={`rds-maintenance-contact-tray-btn`}>
                    <a href={`tel:${franchiseDetails?.headerFranchisePhone}`} title={`CALL NOW : ${formatPhoneNumber(franchiseDetails?.headerFranchisePhone)}`}>
                      <span className="rds rds-btn primary">
                        CALL NOW : {formatPhoneNumber(franchiseDetails?.headerFranchisePhone)}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="rds-maintenance-contact-tray-right-content p-4 mt-4 mb-4">
                  <RdsContactForm {...maintenanceEnrollForm} onFormSubmission={onFormSubmission} formSubmissionError={formSubmissionError} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RdsMaintenanceContactTray;
